/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import { useStaticQuery, graphql } from "gatsby"
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import 'rc-tabs/assets/index.css';
import Box from "../../../components/Box"
import Text from '../../../components/Text';
import Container from '../../../components/Container';
import SectionWrapper from './updateScreen.style';


const Policies = ({ secTitleWrapper, secText, secHeading, subTitle }) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        POLICIES {
          description{
            id
            title
            sections{
              subtitle
              desc1
              desc2
              desc3
              desc4
              desc5
              desc6
              desc7
              desc8
              desc9
              desc10
            }
          }
        }
      }
    }
  `)
  const { description } = Data.dataJson.POLICIES
  const exepCookis = ['Cookies', 'Verwendung von Google Analytics', 'Google AdWords']
  return (
    <SectionWrapper>
      <Container >
        <Tabs
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent animated={false} />}
          className="update-screen-tab"
        >
          {description.map((item, index) => (
            <TabPane
              tab={
                <>
                  {item.title}
                </>
              }
              key={index + 1}
            >{item.id === 1 &&
              item.sections.map((item, index) => (
                <Box {...secTitleWrapper} key={index} id='1'>

                  <Text {...secText} content={item.subtitle} />

                  <Text {...secHeading} content={item.desc1} />

                  <Text  {...secHeading} content={item.desc2} />

                  <Text  {...secHeading} content={item.desc3} />

                  <Text  {...secHeading} content={item.desc4} />

                </Box>

              ))}
              {item.id === 2 &&
                item.sections.map((item, index) => (
                  <Box {...secTitleWrapper} key={index} id="2">
                    {exepCookis.includes(item.subtitle) ?
                      <Text {...subTitle} content={item.subtitle} />
                      :
                    <Text {...secText} content={item.subtitle} />}
                    <Text  {...secHeading} content={item.desc1} />
                    <Text  {...secHeading} content={item.desc2} />
                    <Text  {...secHeading} content={item.desc3} />
                    <Text  {...secHeading} content={item.desc4} />
                    <Text  {...secHeading} content={item.desc5} />
                    <Text  {...secHeading} content={item.desc6} />
                    <Text  {...secHeading} content={item.desc7} />
                    <Text  {...secHeading} content={item.desc8} />
                    <Text  {...secHeading} content={item.desc9} />
                    <Text  {...secHeading} content={item.desc10} />

                  </Box>

                ))}
              {item.id === 3 &&
                item.sections.map((item, index) => (
                  <Box {...secTitleWrapper} key={index} >

                    <Text {...secText} content={item.subtitle} id="3" />
                    <Text  {...secHeading} content={item.desc1} />
                    <Text  {...secHeading} content={item.desc2} />
                    <Text  {...secHeading} content={item.desc3} />
                    <Text  {...secHeading} content={item.desc4} />
                    <Text  {...secHeading} content={item.desc5} />
                    <Text  {...secHeading} content={item.desc6} />
                    <Text  {...secHeading} content={item.desc7} />
                    <Text  {...secHeading} content={item.desc8} />
                    <Text  {...secHeading} content={item.desc9} />
                    <Text  {...secHeading} content={item.desc10} />

                  </Box>

                ))}
            </TabPane>
          ))}
        </Tabs>
      </Container>
    </SectionWrapper>
  );
};

Policies.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  subTitle: PropTypes.object,

};

Policies.defaultProps = {
  secTitleWrapper: {
    ml: ['5%'],
    mt: ['4%'],
    mb: ['4%'],
    width: ['60%']
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: ['16px', '16px', '20px', '20px'],
    letterSpacing: '0.08em',
    fontWeight: '700',
    color: '#767676',
    mb: '2%'


  },
  secHeading: {
    textAlign: 'left',
    fontSize: ['14px', '14px', '16px', '16px'],
    fontWeight: '350',
    color: '#b5b5b6',
    letterSpacing: '-0.025em',
    lineHeight: '1.56',
    width: '90%',
  },
  subTitle: {
    textAlign: 'left',
    fontSize: ['17px', '18px', '28px', '28px'],
    fontWeight: '700',
    color: '#98e044',
    letterSpacing: '-0.025em',
    lineHeight: '1.12',
    width: '90%',
  },
};

export default Policies;
